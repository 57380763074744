import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'  // 直接导入 Vuex Store 实例
import globalfun from '@/assets/js/globalfun'
import { apiJudgeHadOrderDetail } from '@/api/modules/cad_api'
import { apiCanGetCertificate, apiCheckUserId } from '@/api/modules/company_api'
import { apiWebwisdomylCertificate } from '@/api/modules/wisdomyl_api'

/* 路径规则：
	1. 若文件夹中有与文件夹同名的 vue（需含 router-view）, 则使用 ->
	{
		path: '/vue名称',
		name: 'vue名称',
		component: () => import('vue路径'),
		children: [
			{
				path: '/vue名称',
				redirect: '/vue名称/', // 重定向，选择子级中最先展示的页面路径
			},
		]
	}；（如个人中心的 path: '/personal'）

	2. 若无同名 vue ，可分开写入路径，也可取新路径名加 children （如 path: '/cad/pay')
 */
const routes = [
	{
		path: '/',
		redirect: '/cad/index'
	},
	{  // 当没有该路由时
		path: '/:pathMatch(.*)',
		redirect: '/nopage',
	},
	{
		path: '/nopage',
		name: 'nopage',
		component: () => import('../views/NoPage.vue'),
	},
	// 个人版 个人中心
	{
		path: '/cad',
		redirect: '/cad/index',
		children: [
			{
				path: '/cad/index',
				name: 'cad_index',
				component: () => import('../views/cad/home/Home.vue'),
			},
			{
				path: '/cad/privilegeDetail',
				name: 'cad_privilegeDetail',
				component: () => import('../views/cad/home/PrivilegeDetail.vue'),
			},
			{
				path: '/cad/paynew',
				redirect: '/cad/drawpay',
				children: [
					{
						path: '/cad/drawpay',
						name: 'cad_drawpay',
						component: () => import('../views/cad/pay/DrawPay.vue'),
					},
					{
						path: '/cad/gallery',
						name: 'cad_gallery',
						component: () => import('../views/cad/pay/Gallery.vue'),
					},
					{
						path: '/cad/sdrawpay',
						name: 'cad_sdrawpay',
						component: () => import('../views/cad/pay/SdrawPay.vue'),
					},
					{
						path: '/cad/minisd',
						name: 'cad_minisd',
						component: () => import('../views/cad/pay/MiniSd.vue'),
					},
				]
			},
			{
				path: '/personal',
				name: 'personal',
				component: () => import('../views/cad/personal/Personal.vue'),
				beforeEnter: [routeGuardPersonal],
				children: [
					{
						path: '/personal',
						redirect: '/personal/userinfo',
					},
					{
						path: '/personal/userinfo',
						name: 'personal_userinfo',
						component: () => import('../views/cad/personal/UserInfo.vue'),
						// meta: {
						// 	keepAlive: true // 需要缓存
						// }
					},
					{
						path: '/personal/uppwd',
						name: 'personal_uppwd',
						component: () => import('../views/cad/personal/UpPwd.vue'),
					},
					{
						path: '/personal/usercancel',
						name: 'personal_usercancel',
						component: () => import('../views/cad/personal/UserCancel.vue'),
					},
					{
						path: '/personal/bindweixin',
						name: 'personal_bindweixin',
						component: () => import('../views/cad/personal/BindWechat.vue'),
					},
					{
						path: '/personal/vipinfo',
						name: 'personal_vipinfo',
						component: () => import('../views/cad/personal/VipInfo.vue'),
					},
					{
						path: '/personal/purchaseRecord',
						name: 'personal_purchaseRecord',
						component: () => import('../views/cad/personal/PurchaseRecord.vue'),
					},
					{
						path: '/personal/orderinfo',
						name: 'personal_orderinfo',
						component: () => import('../views/cad/personal/Orderinfo.vue'),
					},
					{
						path: '/personal/userinvoice1',
						name: 'personal_userinvoice1',
						component: () => import('../views/cad/personal/Userinvoice1.vue'),
					},
					{
						path: '/personal/userinvoice2',
						name: 'personal_userinvoice2',
						component: () => import('../views/cad/personal/Userinvoice2.vue'),
					},
					{
						path: '/personal/myCDKey',
						name: 'personal_myCDKey',
						component: () => import('../views/cad/personal/MyCDKey.vue'),
					},
					{
						path: '/personal/myConsumedCDKey',
						name: 'personal_myConsumedCDKey',
						component: () => import('../views/cad/personal/MyConsumedCDKey.vue'),
					},
					{
						path: '/personal/orderdetail',
						name: 'personal_orderdetail',
						component: () => import('../views/cad/personal/orderDetail/OrderDetail.vue'),
					},
				],
			},
			{
				path: '/user',
				name: 'user',
				component: () => import('../views/cad/user/User.vue'),
				children: [
					{
						path: '/user',
						redirect: '/cad/reg',
					},
					{
						path: '/user/dealRpstone',
						name: 'user_dealRpstone',
						component: () => import('../views/cad/user/DealRpstone.vue'),
					},
					{
						path: '/cad/reg',
						name: 'cad_reg',
						component: () => import('../views/cad/user/Register.vue'),
					},
					{
						path: '/user/unfreezeAccount',
						name: 'user_unfreezeAccount',
						component: () => import('../views/cad/user/UnfreezeAccount.vue'),
					}
				]
			},
			{
				path: '/cad/autologin',
				name: 'cad_autologin',
				component: () => import('../views/cad/others/AutoLogin.vue'),
			},
			{
				path: '/user/logout',
				name: 'user_logout',
				component: () => import('../views/cad/others/Logout.vue'),
			},
		]
	},
	// 企业版-快速CAD 
	{
		path: '/company',
		redirect: '/company/home',
		children: [
			{
				path: '/company/home',
				name: 'company_home',
				component: () => import('../views/company/home/Home.vue'),			
			},
			// {
			// 	path: '/company/agreements',
			// 	redirect: '/company/agreement',
			// 	children: [
			// 		{
			// 			path: '/company/agreement',
			// 			name: 'company_agreement',
			// 			component: () => import('../views/company/agreements/Agreement.vue'),
			// 		},
			// 		{
			// 			path: '/company/agreementuser',
			// 			name: 'company_agreementuser',
			// 			component: () => import('../views/company/agreements/Agreementuser.vue'),
			// 		},
			// 		{
			// 			path: '/company/onlineservice',
			// 			name: 'company_onlineservice',
			// 			component: () => import('../views/company/agreements/OnlineService.vue'),
			// 		},
			// 		{
			// 			path: '/company/privacy',
			// 			name: 'company_privacy',
			// 			component: () => import('../views/company/agreements/Privacy.vue'),
			// 		},
			// 	]
			// },
			{
				path: '/company/paynew',
				name: 'company_paynew',
				component: () => import('../views/company/pay/Pay.vue'),
			},
			{
				path: '/company/aboutus',
				name: 'company_aboutus',
				component: () => import('../views/company/aboutus/AboutUs.vue'),
			},
			{
				path: '/companypersonal',
				name: 'companypersonal',
				component: () => import('../views/company/personal/Personal.vue'),
				beforeEnter: [routeGuardCompanypersonal],
				children: [
					{
						path: '/companypersonal',
						redirect: '/companypersonal/userinfo',
					},
					{
						path: '/companypersonal/userinfo',
						name: 'companypersonal_userinfo',
						component: () => import('../views/company/personal/UserInfo.vue'),
					},
					{
						path: '/companypersonal/uppwd',
						name: 'companypersonal_uppwd',
						component: () => import('../views/company/personal/UpPwd.vue'),
					},
					{
						path: '/companypersonal/usercancel',
						name: 'companypersonal_usercancel',
						component: () => import('../views/company/personal/UserCancel.vue'),
					},
					{
						path: '/companypersonal/bindwx',
						name: 'companypersonal_bindwx',
						component: () => import('../views/company/personal/BindWechat.vue'),
					},
					{
						path: '/companypersonal/vipinfo',
						name: 'companypersonal_vipinfo',
						component: () => import('../views/company/personal/VipInfo.vue'),
					},
					{
						path: '/companypersonal/purchaseRecord',
						name: 'companypersonal_purchaseRecord',
						component: () => import('../views/company/personal/PurchaseRecord.vue'),
					},
					{
						path: '/companypersonal/orderinfo',
						name: 'companypersonal_orderinfo',
						component: () => import('../views/company/personal/Orderinfo.vue'),
					},
					{
						path: '/companypersonal/userinvoice1',
						name: 'companypersonal_userinvoice1',
						component: () => import('../views/company/personal/Userinvoice1.vue'),
					},
					{
						path: '/companypersonal/userinvoice2',
						name: 'companypersonal_userinvoice2',
						component: () => import('../views/company/personal/Userinvoice2.vue'),
					},
					{
						path: '/companypersonal/certificate',
						name: 'companypersonal_certificate',
						component: () => import('../views/company/personal/Certificate.vue'),
					},
					{
						path: '/companypersonal/gallerystatus',
						name: 'companypersonal_gallerystatus',
						component: () => import('../views/company/personal/GalleryStatus.vue'),
					},
					{
						path: '/companypersonal/galleryRecord',
						name: 'companypersonal_galleryRecord',
						component: () => import('../views/company/personal/GalleryRecord.vue'),
					},
					{
						path: '/companypersonal/galleryorderinfo',
						name: 'companypersonal_galleryorderinfo',
						component: () => import('../views/company/personal/GalleryOrderinfo.vue'),
					},
					{
						path: '/companypersonal/myCDKey',
						name: 'companypersonal_myCDKey',
						component: () => import('../views/company/personal/MyCDKey.vue'),
					},
					{
						path: '/companypersonal/myConsumedCDKey',
						name: 'companypersonal_myConsumedCDKey',
						component: () => import('../views/company/personal/MyConsumedCDKey.vue'),
					},
				],
			},
			{
				path: '/company/government',
				name: 'company_government',
				component: () => import('../views/company/government/Government.vue'),
			},
			{
				path: '/company/user',
				name: 'company_user',
				component: () => import('../views/company/user/User.vue'),
				children: [
					{
						path: '/company/user',
						redirect: '/company/reg',
					},
					{
						path: '/company/dealrpstone',
						name: 'company_dealrpstone',
						component: () => import('../views/company/user/Dealrpstone.vue'),
					},
					{
						path: '/company/reg',
						name: 'company_reg',
						component: () => import('../views/company/user/Register.vue'),
					},
					{
						path: '/company/unfreezeCompanyAccount',
						name: 'company_unfreezeCompanyAccount',
						component: () => import('../views/company/user/UnfreezeAccount.vue'),
					}
				]
			},
			{
				path: '/company/autologin',
				name: 'company_autologin',
				component: () => import('../views/company/others/AutoLogin.vue'),
			},
		]
	},
	// CAD智绘园林
	{
		path: '/wisdomyl',
		redirect: '/wisdomyl/home',
		children: [
			{
				path: '/wisdomyl/home',
				name: 'wisdomyl_home',
				component: () => import('../views/wisdomyl/home/Home.vue'),			
			},
			{
				path: '/wisdomyl/paynew',
				name: 'wisdomyl_paynew',
				component: () => import('../views/wisdomyl/pay/Pay.vue'),
			},
			{
				path: '/wisdomyl/htu',
				name: 'wisdomyl_htu',
				component: () => import('../views/wisdomyl/htu/Htu.vue'),
			},
			{
				path: '/wisdomylpersonal',
				name: 'wisdomylpersonal',
				component: () => import('../views/wisdomyl/personal/Personal.vue'),
				beforeEnter: [routeGuardWisdomylpersonal],
				children: [
					{
						path: '/wisdomylpersonal',
						redirect: '/wisdomylpersonal/userinfo',
					},
					{
						path: '/wisdomylpersonal/userinfo',
						name: 'wisdomylpersonal_userinfo',
						component: () => import('../views/wisdomyl/personal/UserInfo.vue'),
					},
					{
						path: '/wisdomylpersonal/uppwd',
						name: 'wisdomylpersonal_uppwd',
						component: () => import('../views/wisdomyl/personal/UpPwd.vue'),
					},
					{
						path: '/wisdomylpersonal/vipinfo',
						name: 'wisdomylpersonal_vipinfo',
						component: () => import('../views/wisdomyl/personal/VipInfo.vue'),
					},
					{
						path: '/wisdomylpersonal/purchaseRecord',
						name: 'wisdomylpersonal_purchaseRecord',
						component: () => import('../views/wisdomyl/personal/PurchaseRecord.vue'),
					},
					{
						path: '/wisdomylpersonal/orderinfo',
						name: 'wisdomylpersonal_orderinfo',
						component: () => import('../views/wisdomyl/personal/Orderinfo.vue'),
					},
					{
						path: '/wisdomylpersonal/userinvoice1',
						name: 'wisdomylpersonal_userinvoice1',
						component: () => import('../views/wisdomyl/personal/Userinvoice1.vue'),
					},
					{
						path: '/wisdomylpersonal/userinvoice2',
						name: 'wisdomylpersonal_userinvoice2',
						component: () => import('../views/wisdomyl/personal/Userinvoice2.vue'),
					},
					{
						path: '/wisdomylpersonal/certificate',
						name: 'wisdomylpersonal_certificate',
						component: () => import('../views/wisdomyl/personal/Certificate.vue'),
					},
					{
						path: '/wisdomylpersonal/myCDKey',
						name: 'wisdomylpersonal_myCDKey',
						component: () => import('../views/wisdomyl/personal/MyCDKey.vue'),
					},
					{
						path: '/wisdomylpersonal/myConsumedCDKey',
						name: 'wisdomylpersonal_myConsumedCDKey',
						component: () => import('../views/wisdomyl/personal/MyConsumedCDKey.vue'),
					},
				],
			},
			{
				path: '/wisdomyl/user',
				name: 'wisdomyl_user',
				component: () => import('../views/wisdomyl/user/User.vue'),
				children: [
					{
						path: '/wisdomyl/user',
						redirect: '/wisdomyl/reg',
					},
					{
						path: '/wisdomyl/dealrpstone',
						name: 'wisdomyl_dealrpstone',
						component: () => import('../views/wisdomyl/user/Dealrpstone.vue'),
					},
					{
						path: '/wisdomyl/reg',
						name: 'wisdomyl_reg',
						component: () => import('../views/wisdomyl/user/Register.vue'),
					},
					{
						path: '/wisdomyl/unfreezeWisdomylAccount',
						name: 'wisdomyl_unfreezeWisdomylAccount',
						component: () => import('../views/wisdomyl/user/UnfreezeAccount.vue'),
					}
				]
			},
			{
				path: '/wisdomyl/autologin',
				name: 'wisdomyl_autologin',
				component: () => import('../views/wisdomyl/others/AutoLogin.vue'),
			},
		]
	},
	// BIM迷你看图
	{
		path: '/bim',
		redirect: '/bim/home',
		children: [
			{
				path: '/bim/home',
				name: 'bim_home',
				component: () => import('../views/bim/home/Home.vue'),			
			},
			{
				path: '/bimpersonal',
				name: 'bimpersonal',
				component: () => import('../views/bim/personal/Personal.vue'),
				children: [
					{
						path: '/bimpersonal',
						redirect: '/bimpersonal/userinfo',
					},
					{
						path: '/bimpersonal/userinfo',
						name: 'bimpersonal_userinfo',
						component: () => import('../views/bim/personal/UserInfo.vue'),
					},
					{
						path: '/bimpersonal/uppwd',
						name: 'bimpersonal_uppwd',
						component: () => import('../views/bim/personal/UpPwd.vue'),
					},
					{
						path: '/bimpersonal/usercancel',
						name: 'bimpersonal_usercancel',
						component: () => import('../views/bim/personal/UserCancel.vue'),
					},
					{
						path: '/bimpersonal/bindweixin',
						name: 'bimpersonal_bindweixin',
						component: () => import('../views/bim/personal/BindWechat.vue'),
					},
					{
						path: '/bimpersonal/vipinfo',
						name: 'bimpersonal_vipinfo',
						component: () => import('../views/bim/personal/VipInfo.vue'),
					},
					{
						path: '/bimpersonal/purchaseRecord',
						name: 'bimpersonal_purchaseRecord',
						component: () => import('../views/bim/personal/PurchaseRecord.vue'),
					},
					{
						path: '/bimpersonal/orderinfo',
						name: 'bimpersonal_orderinfo',
						component: () => import('../views/bim/personal/Orderinfo.vue'),
					},
					{
						path: '/bimpersonal/userinvoice1',
						name: 'bimpersonal_userinvoice1',
						component: () => import('../views/bim/personal/Userinvoice1.vue'),
					},
					{
						path: '/bimpersonal/userinvoice2',
						name: 'bimpersonal_userinvoice2',
						component: () => import('../views/bim/personal/Userinvoice2.vue'),
					},
				],
			},
			{
				path: '/bim/user',
				name: 'bim_user',
				component: () => import('../views/bim/user/User.vue'),
				children: [
					{
						path: '/bim/user',
						redirect: '/bim/reg',
					},
					{
						path: '/bim/dealrpstone',
						name: 'bim_dealrpstone',
						component: () => import('../views/bim/user/Dealrpstone.vue'),
					},
					{
						path: '/bim/reg',
						name: 'bim_reg',
						component: () => import('../views/bim/user/Register.vue'),
					}
				]
			},
			{
				path: '/bim/autologin',
				name: 'bim_autologin',
				component: () => import('../views/bim/others/AutoLogin.vue'),
			},
		]
	},
	// 快速CAD-橱柜设计
	// {
	// 	path: '/chugui',
	// 	redirect: '/chugui/home',
	// 	children: [
	// 		{
	// 			path: '/chugui/home',
	// 			name: 'chugui_home',
	// 			component: () => import('../views/chugui/home/Home.vue'),			
	// 		},
	// 	]
	// },
	// 快速CAD-空压设计
	// {
	// 	path: '/kongya',
	// 	redirect: '/kongya/home',
	// 	children: [
	// 		{
	// 			path: '/kongya/home',
	// 			name: 'kongya_home',
	// 			component: () => import('../views/kongya/home/Home.vue'),			
	// 		},
	// 	]
	// },
	// 快速CAD-线缆设计
	{
		path: '/xianlan',
		redirect: '/xianlan/home',
		children: [
			{
				path: '/xianlan/home',
				name: 'xianlan_home',
				component: () => import('../views/xianlan/home/Home.vue'),			
			},
		]
	},
	// 激活账号
	{
		path: '/jihuo',
		redirect: '/jihuo/home',
		children: [
			{
				path: '/jihuo/home',
				name: 'jihuo_home',
				component: () => import('../views/jihuo/home/Home.vue'),			
			},
		]
	},
	// 迷你行业化 | 合作伙伴
	{
		path: '/partner',
		redirect: '/partner/home',
		children: [
			{
				path: '/partner/home',
				name: 'partner_home',
				component: () => import('../views/partner/home/Home.vue'),			
			},
			{
				path: '/partner/info',
				name: 'partner_info',
				component: () => import('../views/partner/info/Info.vue'),			
			},
		]
	},
	// app 下载迷你CAD移动端
	{
		path: '/app',
		redirect: '/app/download',
		children: [
			{
				path: '/app/download',
				name: 'app_download',
				component: () => import('../views/app/download/DownLoad.vue'),			
			}
		]
	},
	// 数学工具软件-Maple中文学生版
	{
		path: '/maple',
		redirect: '/maple/home',
		children: [
			{
				path: '/maple/home',
				name: 'maple_home',
				component: () => import('../views/maple/home/Home.vue'),			
			},
			{
				path: '/maple/buy',
				name: 'maple_buy',
				component: () => import('../views/maple/buy/Buy.vue'),			
			},
			{
				path: '/maple/reg',
				name: 'maple_reg',
				component: () => import('../views/maple/reg/Reg.vue'),			
			},
			{
				path: '/maple/login',
				name: 'maple_login',
				component: () => import('../views/maple/login/Login.vue'),			
			},
			{
				path: '/maple/agreement',
				name: 'maple_agreement',
				component: () => import('../views/maple/agreement/Agreement.vue'),			
			},
		]
	},
	// 领取试用会员
	{
		path: '/coupon',
		redirect: '/coupon/tryvip',
		children: [
			{
				path: '/coupon/tryvip',
				name: 'coupon_tryvip',
				component: () => import('../views/coupon/tryvip/TryVip.vue'),			
			}
		]
	},
	// 活动
	{
		path: '/activity',
		name: 'activity',
		component: () => import('../views/activity/Activity.vue'),
	}
	// 离线登录
	// {
	// 	path: '/offline',
	// 	redirect: '/offline/home',
	// 	children: [
	// 		{
	// 			path: '/offline/home',
	// 			name: 'offline_home',
	// 			component: () => import('../views/offline/home/Home.vue'),			
	// 		}
	// 	]
	// },
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		// 如果有 hash 则进行锚点跳转
		if (to.hash) {
		  	return { el: to.hash, }
		} else {
			// 每次保证跳转至页面顶部
			return { top: 0 }
		}
	},
})

// 当捕获到了Loading chunk {n} failed的错误时，重新渲染目标页面。
router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = router.history.pending.fullPath;
    if(isChunkLoadFailed){
        router.replace(targetPath);
    }
})

function changeIcoAndTitle(url, title) {
	var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
	link.type = 'image/x-icon';
	link.rel = 'shortcut icon';
	link.href = url;
	document.getElementsByTagName('head')[0].appendChild(link);

	document.title = title;
}

// --- 路由守卫 ---
// 个人版 个人中心
async function routeGuardPersonal(to, from, next) {
	const token = store.state.user.token
	if (token) {
		try {
			const res = await apiJudgeHadOrderDetail({ user_token: token });
			if (res.code == 1) {
				store.commit('cad/setShowOrder', res.param1 == '1');
			} else {
				store.commit('cad/setShowOrder', false);
			}
		} catch (error) {
			store.commit('cad/setShowOrder', false);
		}

		if (to.fullPath == '/personal/orderdetail') {
			if (store.state.cad.showOrder) {
				next()
			} else {
				next('/personal')
			}
		} else {
			next()
		}
	} else {
		if (from.fullPath != '/') {
			next(from.fullPath)
			setTimeout(() => {
				globalfun.useExistLoginBtn()
			}, 10)
		} else {
			next('/')
		}
	}
}

// 企业版-快速CAD
async function routeGuardCompanypersonal(to, from, next) {
	const token = store.state.user.token
	if (token) {
		try {
			const [certificateRes, userIdRes] = await Promise.all([
				// 是否有企业证书？
				apiCanGetCertificate({ user_token: token }),
				// 是否有购买记录栏目
				apiCheckUserId({ user_token: token })
			]);

			store.commit('company/setShowCertificate', certificateRes.code == 1);
			store.commit('company/setUserId', userIdRes.code == 1);
		} catch (error) {
			store.commit('company/setShowCertificate', false);
			store.commit('company/setUserId', false);
		}

		// 路由是否可进入
		if (to.fullPath == '/companypersonal/certificate') {
			if (store.state.company.showCertificate) {
				next()
			} else {
				next('/companypersonal')
			}
		} else if (to.fullPath == '/companypersonal/purchaseRecord' || to.fullPath == '/companypersonal/orderinfo') {
			if (store.state.company.isUserId) {
				next()
			} else {
				next('/companypersonal')
			}
		} else {
			next()
		}
	} else {
		if (from.fullPath != '/') {
			next(from.fullPath)
			setTimeout(() => {
				globalfun.useExistLoginBtn()
			}, 10)
		} else {
			next('/company')
		}
	}
}

// CAD智绘园林
async function routeGuardWisdomylpersonal(to, from, next) {
	const token = store.state.user.token
	if (token) {
		try {
			const res = await apiWebwisdomylCertificate({ user_token: token })
			store.commit('wisdomyl/setShowCertificate', res.code == 1);
		} catch (error) {
			store.commit('wisdomyl/setShowCertificate', false);
		}

		if (to.fullPath == '/wisdomylpersonal/certificate') {
			if (store.state.wisdomyl.showCertificate) {
				next()
			} else {
				next('/wisdomylpersonal')
			}
		} else {
			next()
		}
	} else {
		if (from.fullPath != '/') {
			next(from.fullPath)
			setTimeout(() => {
				globalfun.useExistLoginBtn()
			}, 10)
		} else {
			next('/wisdomyl')
		}
	}
}

var nowPath = '';
router.beforeEach((to, from, next) => {
	// 防止重复修改 title 与 favicon.ico
	if (to.matched[0].path == nowPath) {
		next();
		return
	} else {
		nowPath = to.matched[0].path;
	}

	// 路由发生变化修改页面title 与 favicon.ico
	if (to.matched[0].path == '/cad') {
		changeIcoAndTitle('/ico/favicon1.ico', 'CAD迷你看图、CAD迷你画图-易学易用的CAD软件「官方下载」');
	} else if (to.matched[0].path == '/company') {
		changeIcoAndTitle('/ico/favicon2.ico', '企业版-快速CAD，易学易用，高效协同，正版授权[官方下载]');
	} else if (to.matched[0].path == '/wisdomyl') {
		changeIcoAndTitle('/ico/favicon2.ico', 'CAD智绘园林，智能高效，参数化数据库，自动化设计，正版授权[官方下载]');
	} else if (to.matched[0].path == '/bim') {
		changeIcoAndTitle('/ico/favicon2.ico', 'BIM-迷你看图，易学易用，高效协同，正版授权[官方下载]');
	} else if (to.matched[0].path == '/chugui') {
		changeIcoAndTitle('/ico/favicon2.ico', '快速CAD-橱柜设计，快速完成橱柜CAD设计，正版授权[官方下载]');
	} else if (to.matched[0].path == '/kongya') {
		changeIcoAndTitle('/ico/favicon2.ico', '快速CAD-空压设计，快速完成空压CAD设计，正版授权[官方下载]');
	} else if (to.matched[0].path == '/xianlan') {
		changeIcoAndTitle('/ico/favicon2.ico', '快速CAD-线缆设计，快速完成线缆CAD设计，正版授权[官方下载]');
	} else if (to.matched[0].path == '/jihuo') {
		changeIcoAndTitle('/ico/favicon2.ico', '企业版-快速CAD，易学易用，高效协同，正版授权[官方下载]');
	} else if (to.matched[0].path == '/partner') {
		changeIcoAndTitle('/ico/favicon3.png', '迷你行业化');
	} else if (to.matched[0].path == '/app') {
		changeIcoAndTitle('/ico/favicon1.ico', 'CAD迷你看图下载');
		let metaEle = document.querySelector('[name="viewport"]');
		metaEle.content = 'width=device-width,initial-scale=1.0,user-scalable=no';
	} else if (to.matched[0].path == '/maple') {
		changeIcoAndTitle('/ico/favicon4.ico', '数学工具软件-Maple中文学生版，丰富课堂教学，加速科研');
	} else if (to.matched[0].path == '/coupon') {
		changeIcoAndTitle('/ico/favicon1.ico', '迷你CAD-领取试用福利');
		let metaEle = document.querySelector('[name="viewport"]');
		metaEle.content = 'width=device-width,initial-scale=1.0,user-scalable=no';
	}
	next();
})

export default router
