// cad 个人中心模块
export default {
    namespaced: true,
    state () {
        return {
            discount_info: '', // 红包信息
            use_pack: false,   // 使用了红包？
            check_pay: {
                timer: '',
                times: 0,
              
                timer2: '',
                times2: 0,
            },  // 检查用户是否扫码及扫码后是否支付的定时器
            showOrder: false,       // 是否显示 "订单信息"
            orderType: '-1', // 开票类型。0：普通类型的开票；1:360 开票；2：联想
        }
    },
    mutations: {
        setCadDiscount(state, payload) {
            state.discount_info = payload;
        },
        setCadUsePack(state, payload) {
            state.use_pack = payload;
        },
        setShowOrder(state, payload) {
            state.showOrder = payload;
        },
    }
}